"use client";

import { ZarazEvents } from "@/app/zaraz-events";

interface ConsoleLinkProps {
  className?: string;
  children: React.ReactNode;
  location: string;
}

export default function ConsoleLink({ className, children, location }: ConsoleLinkProps) {
  const handleClick = () => {
    const baseUrl = "https://console.limbar.io"
    window.zaraz.track(ZarazEvents.CONSOLE_LINK_CLICK, { location });
    if (typeof window !== "undefined" && window.tracker) {
      try {
        // stop() returns the session hash
        const sessionHash = window.tracker.stop();
        console.log({ sessionHash });
        
        const url = new URL(baseUrl);
        if (sessionHash) {
          url.searchParams.set("session", sessionHash);
        }
        
        // Clear the tracker after stopping
        window.tracker = null;
        
        console.log({ url });
        window.location.href = url.toString();
      } catch (error) {
        console.error("Error stopping tracker:", error);
        window.location.href = baseUrl;
      }
    } else {
      console.log({ baseUrl }, "no tracker");
      window.location.href = baseUrl;
    }
  };

  return (
    <button onClick={handleClick} className={className}>
      {children}
    </button>
  );
}