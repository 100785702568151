"use client";

import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { ClipboardIcon } from "@radix-ui/react-icons";
import { ZarazEvents } from "@/app/zaraz-events";

export default function CopyButton({ text }: { text: string }) {
  const [showToast, setShowToast] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setShowToast(true);
    window.zaraz.track(ZarazEvents.INSTALLATION_BUTTON_CLICK, { text });
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => setShowToast(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  return (
    <div className="relative">
      <style jsx>{`
        @keyframes toastAnimation {
          0% {
            opacity: 0;
            transform: translate(-50%, 0);
          }
          10% {
            opacity: 1;
            transform: translate(-50%, -100%);
          }
          90% {
            opacity: 1;
            transform: translate(-50%, -100%);
          }
          100% {
            opacity: 0;
            transform: translate(-50%, -200%);
          }
        }
        .toast-animate {
          animation: toastAnimation 1s ease-in-out;
        }
      `}</style>
      <Button
        onClick={handleCopy}
        variant="ghost"
        className="w-full h-auto min-h-10 justify-between bg-gray-100 hover:bg-gray-200 text-black py-2"
      >
        <span className="font-mono truncate text-left w-full whitespace-pre">{text}</span>
        <ClipboardIcon className="h-4 w-4 min-w-4 min-h-4 ml-4 shrink-0" />
      </Button>

      {showToast && (
        <div
          className="absolute left-1/2 px-2 py-1 bg-black text-white text-xs rounded toast-animate"
          style={{ top: "0", transform: "translateX(-50%)" }}
        >
          Copied!
        </div>
      )}
    </div>
  );
}
