"use client";
import { useEffect } from "react";

import "aos/dist/aos.css";

export default function AOSInit() {
  useEffect(() => {
    import('aos').then((AOS) => {
      AOS.init({
        once: true,
        disable: "phone",
        duration: 700,
        easing: "ease-out-cubic",
      });
    });
  }, []);
  return null; // This component does not render anything in the UI
}
