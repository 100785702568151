"use client";

import { useEffect, useState } from "react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import CopyButton from "@/components/ui/copybutton";
import DownloadButton from "@/components/ui/downloadbutton";
import Link from "next/link";

type OperatingSystem = "macos" | "linux" | "windows" | "other";

const getOS = (): OperatingSystem => {
  if (typeof window === "undefined") return "other";
  const { userAgent } = window.navigator;
  const macos = /(macintosh|macintel|macppc|mac68k|macos)/i.test(userAgent);
  const windows = /(win32|win64|windows|wince)/i.test(userAgent);
  const linux = /linux/i.test(userAgent);
  return macos ? "macos" : windows ? "windows" : linux ? "linux" : "other";
};

export default function InstallationTabs() {
  const [os, setOS] = useState<OperatingSystem>("other");
  useEffect(() => {
    setOS(getOS());
  }, []);

  return (
    <Tabs
      defaultValue="macos"
      value={os === "other" ? "macos" : os}
      onValueChange={(tab) => {
        console.log({ tab }, "clicked");
        setOS(tab as OperatingSystem);
      }}
      className="max-w-xl min-w-64"
    >
      <TabsList className="grid grid-cols-2 md:grid-cols-4 p-1 rounded-lg">
        <TabsTrigger value="macos">macOS</TabsTrigger>
        <TabsTrigger value="windows">Windows</TabsTrigger>
        <TabsTrigger value="linux">Linux</TabsTrigger>
        <TabsTrigger value="github-actions">Github Actions</TabsTrigger>
      </TabsList>
      <TabsContent value="macos">
        <Card>
          <CardHeader>
            <CardDescription className="text-left">
              <Label>Use Homebrew to install lim.</Label>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <CopyButton text="brew install limbario/tap/lim" />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="windows">
        <Card>
          <CardHeader>
            <CardDescription className="text-left">
              <Label>
                For alternative installation methods, go to{" "}
                <Link href="/docs" className="font-semibold">
                  docs
                </Link>
                .
              </Label>
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <DownloadButton
              text="lim-windows-amd64.zip"
              url="https://github.com/limbario/homebrew-tap/releases/latest/download/lim-windows-amd64.zip"
            />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="linux">
        <Card>
          <CardHeader>
            <CardDescription className="text-left">
              <Label>
                For alternative installation methods, go to{" "}
                <Link href="/docs" className="font-semibold">
                  docs
                </Link>
                .
              </Label>
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2 w-full">
            <CopyButton text="curl -sL https://limbar.io/lim.sh | bash" />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="github-actions">
        <Card>
          <CardHeader>
            <CardDescription className="text-left">
              <Label>
                Use, say, 20 emulators to run 20 tests in parallel at no additional cost, drastically reducing your testing time!
              </Label>
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2 w-full">
            <CopyButton 
              text={`- name: Get Android instances
  uses: limbario/run-android@v0.4.3
  with:
    count: 20
    token: \${{ secrets.LIM_TOKEN }}
- name: Maestro
  run: maestro test --shard-split=20`}
            />
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
}
