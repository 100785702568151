// OpenReplayTracker.tsx
"use client";

import { useEffect } from "react";
import type Tracker from "@openreplay/tracker";

// Declare global tracker instance
declare global {
  interface Window {
    tracker: Tracker | null;
    isTrackerInitializing?: boolean;
  }
}

// Initialize the global tracker property
if (typeof window !== "undefined") {
  window.tracker = null;
  window.isTrackerInitializing = false;
}

// Capture session hash from URL immediately, before any React code runs
const sessionHash = typeof window !== "undefined"
  ? new URLSearchParams(window.location.search).get('session')
  : null;
export default function OpenReplayTracker() {
  if (typeof window !== "undefined") {
    useEffect(() => {
      const initTracker = async () => {
        if (window.location.hostname === "localhost") {
          return;
        }
        // Prevent multiple simultaneous initializations
        if (window.isTrackerInitializing || window.tracker) {
          console.log("Tracker initialization already in progress or exists");
          return;
        }

        window.isTrackerInitializing = true;

        try {
          const { default: Tracker } = await import("@openreplay/tracker");

          // Double-check tracker hasn't been created while we were importing
          if (window.tracker) {
            return;
          }

          window.tracker = new Tracker({
            projectKey: "gWS3ka98wPNtujy4I9nR",
            __DISABLE_SECURE_MODE: window.location.hostname === "localhost",
          });
          window.tracker.setMetadata("host", window.location.hostname);
          // Add referrer tracking
          if (document.referrer) {
            window.tracker.setMetadata("referrer", document.referrer);
          }
          const currentSession = await window.tracker.start({
            sessionHash: sessionHash ?? undefined,
          });
          if (currentSession.success) {
            window.zaraz.set("openreplay_session_token", currentSession.sessionToken);
          }
        } catch (error) {
          console.log({ error, sessionHash }, "failed to start openreplay");
          window.tracker = null;
        } finally {
          window.isTrackerInitializing = false;
        }
      };

      initTracker();

      return () => {
        if (window.tracker) {
          window.tracker.stop();
          window.tracker = null;
        }
        window.isTrackerInitializing = false;
      };
    }, []); // Run only once on mount
  }

  return null;
}
